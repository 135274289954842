import * as Sentry from '@sentry/browser';

/**
 * These scrips are to be executed in the <head> of the HTML.
 *
 * This is best (and only) used for initializing analytics and debugging.
 */
window.sentryOptions = window.sentryOptions != null ? window.sentryOptions : {};
Sentry.init(
    window.sentryOptions
);
